export enum Language {
  EN = 'en',
  ZH = 'zh',
  HR = 'hr',
  CS = 'cs',
  DA = 'da',
  NL = 'nl',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  HU = 'hu',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  SL = 'sl',
  ES = 'es',
  SV = 'sv',
  TR = 'tr',
  VI = 'vi',
}

export const detectBrowserLanguage = (): Language => {
  let locale = Language.EN;

  Object.values(Language).forEach((v) => {
    if (navigator.language.match(new RegExp(v, 'i'))) {
      locale = v;
    }
  });

  return locale;
};

import * as React from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import {detectBrowserLanguage, Language} from './Language';
import defaultDictionaries from './I18N';

export type LocalizedDictionary = {[key: string]: string};
export type Dictionaries = {[key in Language]: LocalizedDictionary};

export class Translator {
  @observable localeChangePending = false;
  @observable private _activeLocale: Language = Language.EN;
  @observable private _currentLocale: Language = Language.EN;
  @observable private _activeDictionary: LocalizedDictionary | undefined;
  private loggerTimeout: number = 0;
  private missingPhrases: string[] = [];
  private dictionaries!: Dictionaries;
  private languages: Language[] = Object.values(Language);

  constructor() {
    makeObservable(this);
  }

  async init(language?: Language) {
    for (const lang of this.languages) {
      if (!this.dictionaries) {
        this.dictionaries = {} as Dictionaries;
      }

      this.dictionaries[lang] = defaultDictionaries[lang];
    }

    let lang: Language | undefined = language ?? detectBrowserLanguage();
    if (lang) {
      this.setActiveLocale(lang);
    }
  }

  @computed get activeLocale(): Language {
    return this._activeLocale;
  }

  @computed get activeDictionary(): LocalizedDictionary | undefined {
    return this._activeDictionary;
  }

  @computed get currentLocale(): Language {
    return this._currentLocale;
  }

  @action
  setActiveLocale(locale: Language) {
    this.loadDictionary(locale);
  }

  @action
  loadDictionary(locale: Language) {
    this.localeChangePending = true;

    this._currentLocale = locale;
    let dict = this.dictionaries[locale];

    if (!dict) {
      locale = Language.EN;
      dict = this.dictionaries[Language.EN];
    }

    action(() => {
      this._activeLocale = locale;
      this._activeDictionary = dict;
    })();
  }

  translate(phrase: string, params?: {[key: string]: string}): string {
    let text = this.getPhraseText(phrase);

    if (params) {
      text = text.replace(/{{([a-z\d]+)}}/gi, (found, matched) => {
        return params[matched];
      });
    }

    return text;
  }

  translateWithJsx(phrase: string, data: {[key: string]: (elementKey: number) => React.ReactNode | string}) {
    const text = this.getPhraseText(phrase);

    const parts: (string | React.ReactNode)[] = [];
    let key = 0;

    text.split('{{').forEach((p) => {
      const subParts = p.split('}}');

      if (data[subParts[0]]) {
        parts.push(data[subParts[0]](key));
      } else if (subParts.length > 1) {
        parts.push(`{{${subParts[0]}}}`);
      } else {
        parts.push(subParts[0]);
      }

      key += key;

      if (subParts[1]) {
        parts.push(subParts[1]);

        key += key;
      }
    });

    return parts;
  }

  private getPhraseText(phrase: string, withoutDecorate: boolean = false) {
    let text = this.findPhrase(phrase);
    if (!text) {
      this.logMissing(phrase); // for dev for easier overview what is missing
      text = withoutDecorate ? phrase : `_${phrase}`;
    }

    return text;
  }

  private findPhrase(phrase: string): string | undefined {
    const dict = this._activeDictionary;

    if (!dict) {
      return `${phrase}`;
    }

    return dict[phrase];
  }

  private logMissing(phrase: string) {
    this.missingPhrases.push(phrase);

    if (this.loggerTimeout) {
      clearTimeout(this.loggerTimeout as number);
    }

    this.loggerTimeout = setTimeout(() => {
      const data: {[key: string]: string} = {};

      this.missingPhrases.forEach((p) => {
        data[p] = p;
      });
    }, 1000) as unknown as number;
  }
}

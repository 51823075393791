import {Translator} from '@lib/Translation';
import {SearchConfig, SearchService} from '@lib/Search';
import {ApiClient} from '@lib/Api';
import {ModalService} from '@lib/Modal';

export class Context {
  readonly translator: Translator;
  readonly modalService: ModalService;
  readonly apiClient: ApiClient;
  readonly searchService: SearchService;

  constructor() {
    this.translator = new Translator();
    this.modalService = new ModalService();
    this.apiClient = new ApiClient(this.translator);
    this.searchService = new SearchService(this.translator, this.apiClient, this.modalService);
  }

  async init(config?: SearchConfig) {
    await this.translator.init(config?.language);
    await this.searchService.init(config);
  }

  async destroy() {
    await this.searchService.destroy();
  }
}
